import React from "react";
import { graphql } from "gatsby";

import { safePrefix, Link } from "~utils";
import {
  Layout,
  HeroBlock,
  ContentBlock,
  PostsBlock,
  PdfViewer,
} from "~components";

const components = {
  HeroBlock,
  ContentBlock,
  PostsBlock,
  PdfViewer,
};

/**
 * @typedef {{
 *  data: GatsbyTypes.BlocksQuery
 * }} Props
 *
 * @extends {React.Component<Props>}
 */
export default class Home extends React.Component {
  render() {
    const { data } = this.props;
    const sections = data.page.frontmatter.sections;
    const parsedSections = data.page.fields.sections;

    return (
      <Layout pageData={data}>
        {sections.map((section, sectionIndex) => {
          const { component, section_id, actions } = section || {};
          const GetSectionComponent = components[component];
          const content = parsedSections[sectionIndex].content;

          if (!GetSectionComponent) {
            console.error(
              `Invalid component, "${component}" for section "${section_id}"`,
            );
            return null;
          }

          const actionList = actions?.length ? (
            <p className="block-cta">
              {actions.map((action, action_idx) => (
                <Link
                  key={`${action.url}-${action_idx}`}
                  to={safePrefix(action.url)}
                  className="button"
                  external={action.external}
                  download={action.download}
                >
                  {action?.label}
                </Link>
              ))}
            </p>
          ) : null;

          return (
            <GetSectionComponent
              key={sectionIndex}
              section={{ ...section, content }}
              actions={actionList}
            />
          );
        })}
      </Layout>
    );
  }
}

export const query = graphql`
  query Blocks($url: String!, $headerImg: String) {
    ...PageData
    page: markdownRemark(fields: { url: { eq: $url } }) {
      fields {
        sections {
          content
        }
      }
      frontmatter {
        sections {
          section_id
          component
          title
          pdf
          num_posts_displayed
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 80) {
                ...FluidImg
              }
            }
          }
          actions {
            label
            url
            external
            download
          }
        }
      }
    }
  }
`;
